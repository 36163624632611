import { render, staticRenderFns } from "./Updated.vue?vue&type=template&id=034fb082&scoped=true&"
import script from "./Updated.vue?vue&type=script&lang=js&"
export * from "./Updated.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "034fb082",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/travis/build/Connected-Places/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('034fb082')) {
      api.createRecord('034fb082', component.options)
    } else {
      api.reload('034fb082', component.options)
    }
    module.hot.accept("./Updated.vue?vue&type=template&id=034fb082&scoped=true&", function () {
      api.rerender('034fb082', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/events/Updated.vue"
export default component.exports